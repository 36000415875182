import React from "react"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import { breakpoints } from "../theme"
import { body1, title1 } from "./global-styles"
import Button from "./button"
import { GatsbyImage } from "gatsby-plugin-image"
import "./service-block.scss"

const Container = styled.div`
  margin-bottom: 159px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 0;
  }
`
const Title = styled.h2`
  ${title1};
  margin-bottom: 32px;
  white-space: break-spaces;
`
const Body = styled.p`
  ${body1};
  margin-bottom: 64px;
`

const ServicesBlock = ({
  theme,
  title,
  body,
  imageClassName,
  image,
  imageAlt,
  buttonText,
  buttonHref,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <GatsbyImage
        objectFit="contain"
        className={imageClassName}
        image={image}
        alt={imageAlt} />
      <Title>{title}</Title>
      <Body>{body}</Body>
      <Link to={buttonHref} style={{ width: "auto" }}>
        <Button
          size="big"
          variant="outlined"
          bgColor={theme.body}
          color={theme.buttonColor}
        >
          {buttonText}
        </Button>
      </Link>
    </Container>
  )
}

export default withTheme(ServicesBlock)
