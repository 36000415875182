import React from "react"
import styled, { useTheme } from "styled-components"
import IntlText from "../../components/intl-text"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import PageWrapper from "../../components/page-wrapper"
import ServicesBlock from "../../components/services-block"
import { PRODUCT_DELIVERY_TEAM_ALT, STAFF_AUGMENTATION_ALT } from "../../constants/Constants"
import { SERVICE_PRODUCT_TEAM_PAGE, SERVICE_STAFF_AUGMENTATION_PAGE } from "../../navigation"
import { breakpoints } from "../../theme"
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image"

const sources = {
  dark: {
    staffAugmentation: "staff-augmentation/dark/service.png",
    productDelivery: "delivery-team/dark/service.png",
  },
  light: {
    staffAugmentation: "staff-augmentation/light/service.png",
    productDelivery: "delivery-team/light/service.png",
  }
};

const TitleContainer = styled.div`
  margin-bottom: 95px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 55px;
  }
`
const ItemsContainer = styled.div`
  max-width: 1000px;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 45px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    margin: 0 auto;
  }
`

const Services = () => {
  const { isDark } = useTheme();
  const data = useStaticQuery(graphql`
  query imageServices {
    staff :allFile(filter: {relativePath: { regex: "/staff-augmentation/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [WEBP],
                aspectRatio: 1
              )
            }
        }
      }
    }

    delivery :allFile(filter: {relativePath: { regex: "/delivery-team/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [WEBP]
              )
            }
        }
      }
    }
  }
`);
  const images = isDark ? sources.dark : sources.light;

  return (
    <Layout
      pageTitle="Services"
      title="Web and app development services at your fingertips - Sysgarage"
      description="We offer a team of experts working on your products to speed or scale your Webapp or app development. Contact Us!"
    >
      <PageWrapper>
        <TitleContainer>
          <PageHeader
            title={<IntlText id="pages.services.title" />}
            body={<IntlText type="h2" id="pages.services.subtitle" /> }
          />
        </TitleContainer>

        <ItemsContainer className="row">
          <div className="col-md-6">
            <ServicesBlock
              imageClassName="image-service-block-A"
              image={getImage(data.delivery.edges.filter((j) => j.node.relativePath.includes(images.productDelivery))[0]?.node.childImageSharp)}
              imageAlt={PRODUCT_DELIVERY_TEAM_ALT}
              title={<IntlText id="pages.services.items.product_team.title" />}
              body={
                <IntlText id="pages.services.items.product_team.description" />
              }
              buttonText={
                <IntlText id="pages.services.items.product_team.button" />
              }
              buttonHref={SERVICE_PRODUCT_TEAM_PAGE}
              className="last"
            />
          </div>
          <div className="col-md-6">
            <ServicesBlock
              imageClassName="image-service-block-B"
              image={getImage(data.staff.edges.filter((j) => j.node.relativePath.includes(images.staffAugmentation))[0]?.node.childImageSharp)}
              imageAlt={STAFF_AUGMENTATION_ALT}
              title={
                <IntlText id="pages.services.items.staff_augmentation.title" />
              }
              body={
                <IntlText id="pages.services.items.staff_augmentation.description" />
              }
              buttonText={
                <IntlText id="pages.services.items.staff_augmentation.button" />
              }
              buttonHref={SERVICE_STAFF_AUGMENTATION_PAGE}
            />
          </div>
        </ItemsContainer>
      </PageWrapper>
    </Layout>
  )
}

export default Services
